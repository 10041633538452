import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCities } from '../lib/client';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Home = () => {
  const [zipCode, setZipCode] = useState('');
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const citiesData = await getCities();
        setCities(citiesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cities:', error);
        setLoading(false);
      }
    };

    fetchCities();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (zipCode) {
      navigate(`/find-daycare/${zipCode}`);
    }
  };

  const handleCityClick = (cityName, stateCode) => {
    navigate(`/find-daycare/city/${cityName}-${stateCode}`);
  };

  return (
    <div className="overflow-hidden">
      <Navbar />
      <Hero />
      
      {/* Search Section */}
      <div className="search-container" style={{ textAlign: 'center', margin: '20px 0' }}>
        <h2>Find Your Daycare</h2>
        <form onSubmit={handleSearch} className="search-form">
          <input
            type="text"
            placeholder="Enter Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            className="zip-input"
          />
          <button type="submit" className="search-button">Search</button>
        </form>
      </div>

      {/* Browse by City Section */}
      <div className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center mb-8">
            <svg className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <h2 className="ml-2 text-3xl font-bold text-purple-900">Find daycares by city</h2>
          </div>
          
          {loading ? (
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {cities.map((city) => (
                <button
                  key={`${city.name}-${city.state}`}
                  onClick={() => handleCityClick(city.name, city.state)}
                  className="flex items-center justify-between p-4 text-left hover:bg-gray-50 border-b border-gray-200 transition-colors duration-200"
                >
                  <span className="text-gray-900">{city.name}, {city.state}</span>
                  <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <Features />
      <Footer />
    </div>
  );
};


export default Home;