import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { client } from '../lib/client';

const DaycareSearchResults = () => {
  const { zipCode } = useParams();
  const [daycares, setDaycares] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDaycares = async () => {
      try {
        const query = `*[_type == "daycare" && zipCode == "${zipCode}"]{
          _id,
          name,
          address,
          phoneNumber,
          "slug": slug.current
        }`;
        
        const data = await client.fetch(query);
        console.log('Fetched daycares:', data);
        setDaycares(data);
      } catch (err) {
        console.error('Error fetching daycares:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDaycares();
  }, [zipCode]);

  if (loading) {
    return (
      <div className="pt-16 min-h-screen bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="pt-16 min-h-screen bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-red-50 border border-red-200 rounded-md p-4">
            <p className="text-red-600">Error: {error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-16 min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Daycares near {zipCode}
        </h1>

        {daycares.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <h2 className="text-xl text-gray-600">
              No daycares found for this zip code.
            </h2>
            <p className="mt-2 text-gray-500">
              Try searching with a different zip code or contact us for assistance.
            </p>
            <Link 
              to="/" 
              className="mt-4 inline-block text-primary hover:text-primary-dark"
            >
              ← Back to Home
            </Link>
          </div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {daycares.map((daycare) => (
              <div
                key={daycare._id}
                className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow"
              >
                <h2 className="text-xl font-semibold text-gray-900 mb-2">
                  {daycare.name}
                </h2>
                {daycare.address && (
                  <p className="text-gray-600 mb-2">{daycare.address}</p>
                )}
                {daycare.phoneNumber && (
                  <p className="text-gray-600 mb-4">
                    <a
                      href={`tel:${daycare.phoneNumber}`}
                      className="hover:text-primary"
                    >
                      {daycare.phoneNumber}
                    </a>
                  </p>
                )}
                <Link
                  to={`/daycare/${daycare.slug}`}
                  className="text-primary hover:text-primary-dark font-medium"
                >
                  View Details →
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DaycareSearchResults;
