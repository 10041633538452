import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { client, urlFor } from '../lib/client';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const CityDaycares = () => {
  const { cityState } = useParams();
  const [daycares, setDaycares] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDaycares = async () => {
      try {
        const [city, state] = cityState.split('-');
        console.log('Searching for:', { city, state }); // Debug log

        // Updated query to match your data structure
        const query = `*[_type == "daycare" && city == $city && state == $state] {
          _id,
          name,
          slug,
          city,
          state,
          address,
          phoneNumber,
          mainImage,
          description,
          specialNeeds
        }`;

        const params = {
          city: city,
          state: state // The state parameter will be the full state name
        };

        console.log('Query:', query); // Debug log
        const data = await client.fetch(query, params);
        console.log('Found daycares:', data); // Debug log
        
        setDaycares(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching daycares:', error);
        setLoading(false);
      }
    };

    fetchDaycares();
  }, [cityState]);

  if (loading) {
    return (
      <div>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold mb-8">
          Daycares in {cityState.split('-')[0]}, {cityState.split('-')[1]}
        </h1>
        
        {daycares.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500">No daycares found in this location.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {daycares.map((daycare) => (
              <div 
                key={daycare._id} 
                className="border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 bg-white"
              >
                {daycare.mainImage && (
                  <img 
                    src={urlFor(daycare.mainImage).width(400).height(300).url()} 
                    alt={daycare.name}
                    className="w-full h-48 object-cover rounded-t-lg"
                  />
                )}
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2">{daycare.name}</h2>
                  <p className="text-gray-600 mb-2">{daycare.address}</p>
                  <p className="text-gray-600 mb-4">{daycare.phoneNumber}</p>
                  {daycare.description && (
                    <p className="text-gray-600 mb-4 line-clamp-3">{daycare.description}</p>
                  )}
                  <button 
                    onClick={() => navigate(`/daycare/${daycare.slug.current}`)}
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-200"
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CityDaycares; 
