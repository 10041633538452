import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const About = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow bg-gray-50">
        <div className="pt-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto py-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-8">About Us</h1>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              {/* Mission Section */}
              <div>
                <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
                <p className="text-gray-600 mb-6">
                  At AbleCub, our mission is to connect parents with exceptional special needs 
                  daycare facilities. We strive to be the most comprehensive directory of specialized 
                  early childhood care centers, making it easier for families to find the perfect 
                  supportive environment for their children's unique needs.
                </p>
                
                <h2 className="text-2xl font-semibold mb-4">Our Values</h2>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-center">
                    <span className="mr-2">🌟</span>
                    <span>Inclusive Care Excellence</span>
                  </li>
                  <li className="flex items-center">
                    <span className="mr-2">💕</span>
                    <span>Specialized Support Access</span>
                  </li>
                  <li className="flex items-center">
                    <span className="mr-2">🤝</span>
                    <span>Family-Provider Connection</span>
                  </li>
                  <li className="flex items-center">
                    <span className="mr-2">🎯</span>
                    <span>Personalized Care Matching</span>
                  </li>
                </ul>
                
                <div className="mt-8">
                  <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                  <div className="flex items-center text-gray-600">
                    <span className="mr-2">📧</span>
                    <a 
                      href="mailto:ablecub@gmail.com" 
                      className="text-primary hover:text-primary-dark transition-colors"
                    >
                      ablecub@gmail.com
                    </a>
                  </div>
                </div>
              </div>

              {/* Team Section */}
              <div>
                <h2 className="text-2xl font-semibold mb-4">Our Approach</h2>
                <p className="text-gray-600 mb-6">
                  We take pride in our comprehensive approach to connecting families with 
                  special needs daycare facilities. Our platform is designed to:
                </p>
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <div className="bg-primary/10 p-2 rounded-full mr-4">
                        <span className="text-xl">📚</span>
                      </div>
                      <div>
                        <h3 className="font-semibold">Detailed Facility Profiles</h3>
                        <p className="text-gray-600">Comprehensive information about each center's specializations and capabilities</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <div className="bg-primary/10 p-2 rounded-full mr-4">
                        <span className="text-xl">🎨</span>
                      </div>
                      <div>
                        <h3 className="font-semibold">Specialized Care Matching</h3>
                        <p className="text-gray-600">Helping parents find facilities that match their child's specific needs</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <div className="bg-primary/10 p-2 rounded-full mr-4">
                        <span className="text-xl">🤗</span>
                      </div>
                      <div>
                        <h3 className="font-semibold">Community Support</h3>
                        <p className="text-gray-600">Connecting families with resources and support networks</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default About;
