import { createClient } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

const config = {
  projectId: '9e7crtpc',
  dataset: 'care-services',
  apiVersion: '2023-11-26',
  useCdn: true
};

console.log('Sanity Config:', config);

export const client = createClient(config);

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);

// Add this debug function
export const debugSanity = async () => {
  try {
    const result = await client.fetch('*[_type == "daycare"]');
    console.log('Sanity Debug:', {
      config: client.config(),
      result
    });
  } catch (error) {
    console.error('Sanity Debug Error:', error); 
  }
};

// Add the new getCities function here
export const getCities = async () => {
  const query = `*[_type == "daycare"] {
    city,
    state
  }`;
  
  const results = await client.fetch(query);
  console.log('Fetched results:', results);
  
  const uniqueCities = Array.from(new Set(
    results
      .filter(item => item.city && item.state)
      .map(item => `${item.city}-${item.state}`)
  ))
  .map(cityState => {
    const [city, state] = cityState.split('-');
    return { name: city, state: state };
  });

  return uniqueCities.sort((a, b) => a.name.localeCompare(b.name));
};