import { motion } from 'framer-motion';
import { FaHeartbeat, FaBook, FaUsers, FaSmile } from 'react-icons/fa';

const features = [
  {
    icon: <FaHeartbeat className="w-8 h-8" />,
    title: 'Specialized Care',
    description: 'Find daycares with expertise in various special needs and medical conditions.',
  },
  {
    icon: <FaBook className="w-8 h-8" />,
    title: 'Verified Credentials',
    description: 'All listed daycares are vetted for proper certifications and qualifications.',
  },
  {
    icon: <FaUsers className="w-8 h-8" />,
    title: 'Parent Reviews',
    description: 'Read authentic experiences from other special needs families.',
  },
  {
    icon: <FaSmile className="w-8 h-8" />,
    title: 'Easy Search',
    description: 'Filter by location, specific needs, and available support services.',
  },
];

const Features = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Special Needs Daycare Directory
          </h2>
          <p className="text-lg text-gray-600">
            Discover daycares that provide specialized care and support for children with special needs.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              whileHover={{ 
                scale: 1.05, 
                y: -10,
                transition: {
                  duration: 0.1,
                  type: "spring",
                  stiffness: 400
                }
              }}
              transition={{ duration: 0.3 }}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl hover:bg-blue-50 transition-all duration-300 cursor-pointer border border-gray-100 hover:border-blue-200"
            >
              <div className="text-primary mb-4 group-hover:text-blue-600">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2 group-hover:text-blue-600">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;