import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { client } from '../lib/client';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const query = `*[_type == "servicesOffered"]{
      _id,
      title,
      description,
      "slug": slug.current
    }`;

    client.fetch(query)
      .then((data) => {
        console.log('Fetched services:', data);
        setServices(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching services:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow">
        <div className="pt-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8 mt-8"></h1>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {services.map((service) => (
              <Link
                key={service._id}
                to={`/services/${service.slug}`}
                className="block bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow p-6"
              >
                <h2 className="text-xl font-semibold text-gray-900 mb-2">
                  {service.title}
                </h2>
                {service.description && (
                  <p className="text-gray-600 line-clamp-3">
                    {service.description}
                  </p>
                )}
                <span className="inline-block mt-4 text-primary hover:text-primary/80">
                  Learn more →
                </span>
              </Link>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Services;