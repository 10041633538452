import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { client } from '../lib/client';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Daycares = () => {
  const [locationsByState, setLocationsByState] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const query = `*[_type == "daycare"]{
      _id,
      name,
      address,
      phoneNumber,
      state,
      "slug": slug.current
    }`;

    client.fetch(query)
      .then((data) => {
        const grouped = data.reduce((acc, location) => {
          const state = location.state || 'Other';
          if (!acc[state]) {
            acc[state] = [];
          }
          acc[state].push(location);
          return acc;
        }, {});
        
        setLocationsByState(grouped);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching locations:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow">
        <div className="pt-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8 mt-8"></h1>
          
          {Object.entries(locationsByState).map(([state, locations]) => (
            <div key={state} className="mb-12">
              <h2 className="text-2xl font-semibold text-gray-800 mb-6">{state}</h2>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {locations.map((location) => (
                  <Link
                    key={location._id}
                    to={`/daycare/${location.slug}`}
                    className="block bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all hover:-translate-y-1"
                  >
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {location.name}
                    </h3>
                    {location.address && (
                      <p className="text-gray-600 mb-2">{location.address}</p>
                    )}
                    {location.phoneNumber && (
                      <p className="text-gray-600">
                        <span 
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `tel:${location.phoneNumber}`;
                          }}
                          className="hover:text-primary cursor-pointer"
                        >
                          {location.phoneNumber}
                        </span>
                      </p>
                    )}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Daycares;