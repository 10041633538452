import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { client } from '../lib/client';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ServiceDetails = () => {
  const { slug } = useParams();
  const [service, setService] = useState(null);
  const [daycares, setDaycares] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch service details
        const serviceQuery = `*[_type == "servicesOffered" && slug.current == $slug][0]{
          title,
          description,
          "slug": slug.current
        }`;

        // Fetch daycares that offer this service
        const daycaresQuery = `*[_type == "daycare" && references(*[_type == "servicesOffered" && slug.current == $slug]._id)]{
          _id,
          name,
          address,
          state,
          phoneNumber,
          "slug": slug.current
        }`;

        const [serviceData, daycaresData] = await Promise.all([
          client.fetch(serviceQuery, { slug }),
          client.fetch(daycaresQuery, { slug })
        ]);

        setService(serviceData);
        setDaycares(daycaresData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </main>
        <Footer />
      </div>
    );
  }

  if (!service) {
    return (
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <div className="pt-16 px-4 text-center">
            <h1 className="text-2xl text-gray-900">Service not found</h1>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow">
        <div className="pt-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          {/* Service Details */}
          <div className="mt-8 bg-white rounded-lg shadow-md p-6 mb-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">{service.title}</h1>
            {service.description && (
              <p className="text-gray-600 whitespace-pre-wrap mb-4">{service.description}</p>
            )}
          </div>

          {/* Daycares offering this service */}
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
              Daycares Offering {service.title}
            </h2>
            
            {daycares.length === 0 ? (
              <p className="text-gray-600">No daycares currently offer this service.</p>
            ) : (
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {daycares.map((daycare) => (
                  <Link
                    key={daycare._id}
                    to={`/daycare/${daycare.slug}`}
                    className="block bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all hover:-translate-y-1"
                  >
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {daycare.name}
                    </h3>
                    {daycare.address && (
                      <p className="text-gray-600 mb-2">{daycare.address}</p>
                    )}
                    {daycare.state && (
                      <p className="text-gray-600 mb-2">{daycare.state}</p>
                    )}
                    {daycare.phoneNumber && (
                      <p className="text-gray-600">
                        <span className="hover:text-primary cursor-pointer">
                          {daycare.phoneNumber}
                        </span>
                      </p>
                    )}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ServiceDetails;