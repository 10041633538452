import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Daycares from './pages/Daycares';
import Services from './pages/Services';
import ServiceDetails from './pages/ServiceDetails';
import Locations from './pages/Daycares';
import DaycareDetails from './pages/DaycareDetails.jsx';
import DaycareSearchResults from './pages/DaycareSearchResults';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import CityDaycares from './pages/CityDaycares';

const router = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/daycares" element={<Daycares />} />
        <Route path="/about" element={<About Us />} />
        <Route path="/daycare" element={<Navigate to="/home" replace />} />
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/daycare/:slug" element={<DaycareDetails />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:slug" element={<ServiceDetails />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/find-daycare/:zipCode" element={<DaycareSearchResults />} />
        <Route path="/daycare/services" element={<Services />} />
        <Route path="/daycare/services/:slug" element={<ServiceDetails />} />
        <Route path="/daycare/locations" element={<Locations />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/locations/:cityName" element={<CityDaycares />} />
        <Route path="/daycares/:city" element={<CityDaycares />} />
        <Route path="/find-daycare/city/:cityState" element={<CityDaycares />} />
      </Routes>
    </Router>
  );
}

export default App;