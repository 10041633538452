import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="relative bg-gradient-to-r from-primary/10 to-secondary/10 pt-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
            Welcome to AbleCub
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            Connecting families with trusted, child-focused daycares.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;