import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Blog = () => {
  const blogPosts = [
    {
      id: 1,
      slug: "speech-therapy",
      title: "Speech Therapy for Children: How It Helps and What to Look for in a Daycare",
      date: "December 2, 2024",
      author: "AbleCub Team",
      excerpt: "Learn about speech therapy and how it can help children develop essential communication skills.",
      category: "Therapy",
    },
    {
      id: 2,
      slug: "physical-therapy",
      title: "Physical Therapy for Children: How It Helps and What to Look for in a Daycare",
      date: "December 2, 2024",
      author: "AbleCub Team",
      excerpt: "Understand how physical therapy can help children develop essential motor skills and improve their overall physical development.",
      category: "Therapy",
    },
    {
      id: 3,
      slug: "applied-behavioral-analysis",
      title: "Applied Behavioral Analysis (ABA): How It Helps Children and What to Look for in a Daycare",
      date: "December 2, 2024",
      author: "AbleCub Team",
      excerpt: "Discover how ABA therapy can help children with autism spectrum disorder develop essential skills.",
      category: "Therapy",
    },
    {
      id: 4,
      slug: "occupational-therapy",
      title: "Occupational Therapy (OT) for Children: How It Helps and What to Look for in a Daycare",
      date: "December 2, 2024",
      author: "AbleCub Team",
      excerpt: "Learn how occupational therapy helps children develop daily living skills and motor functions.",
      category: "Therapy",
    },
    {
      id: 5,
      slug: "autism-spectrum-disorder",
      title: "Autism Spectrum Disorder (ASD): How to Identify It and Choose the Right Daycare",
      date: "December 2, 2024",
      author: "AbleCub Team",
      excerpt: "Understanding autism spectrum disorder and how to support children with ASD in daycare settings.",
      category: "Therapy",
    },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow bg-gray-50">
        <div className="pt-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto py-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-8"></h1>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {blogPosts.map((post) => (
                <article 
                  key={post.id} 
                  className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                >
                  <div className="p-6">
                    <div className="flex items-center mb-4">
                      <span className="inline-block px-3 py-1 bg-primary/10 text-primary rounded-full text-sm">
                        {post.category}
                      </span>
                      <span className="text-gray-500 text-sm ml-4">{post.date}</span>
                    </div>
                    
                    <h2 className="text-xl font-semibold mb-3 hover:text-primary">
                      {post.title}
                    </h2>
                    
                    <p className="text-gray-600 mb-4">
                      {post.excerpt}
                    </p>
                    
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-500">{post.author}</span>
                      <Link 
                        to={`/blog/${post.slug}`} 
                        className="text-primary hover:text-primary-dark font-medium"
                      >
                        Learn More →
                      </Link>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Blog;
