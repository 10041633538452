import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { client } from '../lib/client';
import { FaPhone, FaGlobe, FaMapMarkerAlt, FaYelp } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const hasOperatingHours = (hours) => {
  return hours.some(hour => hour.hours !== "Closed");
};

const DaycareDetails = () => {
  const { slug } = useParams();
  const [daycare, setDaycare] = useState(null);

  useEffect(() => {
    console.log('Fetching daycares...');
    const query = `
      *[_type == "daycare" && slug.current == $slug][0]{
        _id,
        name,
        state,
        slug,
        address,
        phoneNumber,
        websiteLink,
        googleMapsLink,
        yelpLink,
        aboutus,
        image,
        hoursOfOperation,
        "servicesOffered": *[_type == "servicesOffered"]{ 
          title,
          "slug": slug.current,
          description
        },
        faq
      }
    `;
    
    client.fetch(query, { slug })
      .then((data) => {
        console.log('Fetched daycares:', data);
        setDaycare(data);
      })
      .catch((error) => {
        console.error('Error fetching daycares:', error);
      });
  }, [slug]);

  if (!daycare) return <div>Loading...</div>;

  console.log('Services:', daycare.servicesOffered);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow pt-20">
        <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          <Link
            to="/daycares"
            className="mb-6 inline-flex items-center text-primary hover:text-primary-dark"
          >
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Back to Daycares
          </Link>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-6">{daycare.name}</h1>
            
            {daycare.image && (
              <div className="mb-8">
                <img 
                  src={daycare.image.asset.url} 
                  alt={daycare.name}
                  className="w-full max-h-96 object-cover rounded-lg"
                />
              </div>
            )}

            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div className="bg-white rounded-lg shadow-md p-6">
                  <h2 className="text-2xl font-semibold mb-4">Location Details</h2>
                  <div className="space-y-3">
                    <p className="flex items-center gap-2">
                      <FaMapMarkerAlt className="text-blue-600" />
                      {daycare.address}
                    </p>
                    {daycare.googleMapsLink && (
                      <p className="flex items-center gap-2">
                        <FcGoogle className="text-blue-600" />
                        <a href={daycare.googleMapsLink} target="_blank" rel="noopener noreferrer" 
                           className="text-blue-600 hover:underline">
                          View on Google Maps
                        </a>
                      </p>
                    )}
                    {daycare.yelpLink && (
                      <p className="flex items-center gap-2">
                        <FaYelp className="text-red-600" />
                        <a href={daycare.yelpLink} target="_blank" rel="noopener noreferrer" 
                           className="text-blue-600 hover:underline">
                          View on Yelp
                        </a>
                      </p>
                    )}
                    {daycare.phoneNumber && (
                      <p className="flex items-center gap-2">
                        <FaPhone className="text-blue-600" />
                        <a href={`tel:${daycare.phoneNumber}`}>{daycare.phoneNumber}</a>
                      </p>
                    )}
                    {daycare.websiteLink && (
                      <p className="flex items-center gap-2">
                        <FaGlobe className="text-blue-600" />
                        <a href={daycare.websiteLink} target="_blank" rel="noopener noreferrer" 
                           className="text-blue-600 hover:underline">
                          Visit Website
                        </a>
                      </p>
                    )}
                  </div>
                </div>

                {daycare.hoursOfOperation && hasOperatingHours(daycare.hoursOfOperation) && (
                  <div className="bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-2xl font-semibold mb-4">Hours of Operation</h2>
                    <div className="space-y-2">
                      {daycare.hoursOfOperation.map((hours, index) => (
                        <div key={index} className="flex justify-between">
                          <span className="font-medium">{hours.day}</span>
                          <span>{hours.hours}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="space-y-6">
                {daycare.aboutus && (
                  <div className="bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-2xl font-semibold mb-4">About Us</h2>
                    <p className="text-gray-700">{daycare.aboutus}</p>
                  </div>
                )}

                <div className="bg-white rounded-lg shadow-md p-6">
                  <h2 className="text-2xl font-semibold mb-4">Services Offered</h2>
                  <div className="flex flex-wrap gap-2">
                    {daycare?.servicesOffered?.map((service, index) => {
                      console.log('Service data:', service);
                      return (
                        <Link 
                          key={index} 
                          to={`/services/${service.slug}`}
                          className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full hover:bg-blue-200 transition-colors"
                        >
                          {service.title}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default DaycareDetails;